const PREFIX = 'Somkg_Store';

const get = (name, default_value = null) => {
  try {
    const value = JSON.parse(localStorage.getItem(`@${PREFIX}:${name}`));
    return value || default_value;
  } catch (error) {
    console.log(error);
    return default_value;
  }
};

const set = (name, value) => {
  try {
    value = JSON.stringify(value);
    localStorage.setItem(`@${PREFIX}:${name}`, value);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const remove = (name) => {
  try {
    localStorage.removeItem(`@${PREFIX}:${name}`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default {
  get, set, remove,
};
