import React from 'react';
import BaseContainer from "./BaseContainer";
import abilityRequired from "../utils/abilityRequired";

@abilityRequired('users')
class UsersContainer extends BaseContainer {
  render() {
    return (
      <p>
        Users Контент
      </p>
    );
  }
}

export default UsersContainer;
