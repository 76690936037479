import axios from 'axios';
import CustomError from './CustomError';
import {getCustomError} from './index';

export const APP_NAME = 'Som.kg Админ';
export const APP_DESCRIPTION = 'Панель управления Som.kg';
export const APP_COLOR = '#007bff';

export const DEBUG_MODE = process.env.NODE_ENV !== "production";
export const API_VERSION = 1;
export const API_URL = 'https://api.som.kg';
export const API_URL_WITH_VERSION = API_URL + '/v' + API_VERSION;

export const axios_instance = axios.create({
  baseURL: API_URL_WITH_VERSION,
  responseType: 'json',
  responseEncoding: 'utf8',
});

axios_instance._configs = {
  silence: false,
};

export function initialize(store){
  axios_instance.interceptors.request.use(function(config){
    if (store.token) config.headers.common.Authorization = 'Bearer ' + store.token;
    return config;
  }, function (error){
    if (!axios_instance._configs.silence) store.showError(error.message || error);
    if (error instanceof CustomError) throw error;
    throw new CustomError().setError(error);
  });

  axios_instance.interceptors.response.use(function (response){
    if (response.data.status < 0) {
      switch (response.data.result) {
        case 'token_is_expired':
          store.showInfo('Срок действия вашего токена истек, пожалуйста, войдите снова!');
          store.signOut();
          break;
        case 'token_is_invalid':
          store.showInfo('Ваш токен недействителен, пожалуйста, войдите снова!');
          store.signOut();
          break;
      }

      if (!axios_instance._configs.silence) {
        let message = null;

        switch (response.data.result) {
          case 'invalid_params':
            message = 'Параметрлер туура эмес!';
            break;

          default:
            break;
        }
        if (message) store.showError(message);
      }

      return Promise.reject(new CustomError().setResponse(response));
    }

    return 'result' in response ? response : response.data;
  }, function (error){
    error = getCustomError(error);
    if (!axios_instance._configs.silence) store.showError(error.description || error.message);

    switch (error.result) {
      case 'unauthorized':
        store.signOut();
        break;
    }

    return Promise.reject(error);
  });
}
