import React from 'react';
import {action} from "mobx";

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);

    this.rootStore = props.store;

    if (this.rootStore) {
      this.store = this.rootStore.appStore;
      this.placeStore = this.rootStore.placeStore;
      this.categoryStore = this.rootStore.categoryStore;
      this.adStore = this.rootStore.adStore;
      this.userStore = this.rootStore.userStore;
    }
  }

  @action setValue = (name, value) => this[name] = value;
}

export default BaseComponent;
