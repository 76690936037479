import React from 'react';
import BaseContainer from "./BaseContainer";
import {
  MDBBox,
  MDBCollapse,
  MDBContainer,
  MDBFooter,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav, MDBNavbarToggler,
  MDBNavItem, MDBNavLink,
} from "mdbreact";
import {APP_NAME, initialize} from "../utils/settings";
import moment from 'moment';
import HomeContainer from "./HomeContainer";
import UsersContainer from "./UsersContainer";
import Route from '../utils/Route';
import {getParams} from "../utils";
import {inject, observer} from "mobx-react";
import storage from "../utils/storage";
import Loader from "../ui/Loader";
import AdsContainer from "./AdsContainer";
import {action, observable} from "mobx";
import 'moment/locale/ru';

@inject('store')
@observer
class AppContainer extends BaseContainer {
  @observable loading = true;

  constructor(props) {
    super(props);

    this.state = {
      is_open: false,
      route_match: 'home',
    };

    moment.locale('ru');

    this.header_menu = [
      ['Главная', 'home', '/'],
      ['Пользователи', 'users', '/users'],
      ['Объявления', 'ads', '/ads'],
    ];

    const params = getParams();
    if ('token' in params && params.token) {
      this.store.setToken(params.token);
    }

    this.initialize().then(async () => {
      initialize(this.store);
      this.setValue('loading', false);
      await this.store.checkUser();
    });
  }

  @action setValue = (name, value) => this[name] = value;

  initialize = async () => {
    const storage_values = await Promise.all([
      storage.get('token', null),
      storage.get('user', null),
      storage.get('places', null),
      storage.get('categories', null),
    ]);

    this.rootStore.setDataFromStorage(storage_values);
  };

  toggleCollapse = () => {
    this.setState({is_open: !this.state.is_open});
  };

  onChangeMatch = (m) => {
    if (this.state.route_match !== m) this.setState({route_match: m});
  };

  componentDidMount() {
    this.categoryStore.fetchItems();
  }

  render() {
    if (this.loading) {
      return (
        <MDBBox className={'h-100'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </MDBBox>
      );
    }

    else if (
      !this.store.token ||
      (!this.store.authenticating && !this.store.user) ||
      (!this.store.authenticating && this.store.user && this.store.user.abilities.length === 0)
    ) {
      return (
        <MDBBox className={'h-100'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <h1>Доступ закрыт!</h1>
        </MDBBox>
      );
    }

    else if ((!this.store.user || this.store.user.abilities.length === 0) && this.store.authenticating) {
      return (
        <MDBBox className={'h-100'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </MDBBox>
      );
    }

    return (
      <>
        <MDBNavbar color="default-color" dark expand="md">
          <MDBNavbarBrand>
            <strong className="white-text">{APP_NAME}</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse}/>
          <MDBCollapse isOpen={this.state.is_open} navbar>
            <MDBNavbarNav left>
              {this.header_menu.map((v, key) => (
                <MDBNavItem key={key} active={this.state.route_match === v[1]}>
                  <MDBNavLink to={v[2]}>{v[0]}</MDBNavLink>
                </MDBNavItem>
              ))}
              <MDBNavItem>
                <MDBNavLink to="#!">Баннеры</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="#!">Приложение</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="#!">Баннеры</MDBNavLink>
              </MDBNavItem>
              {/*<MDBNavItem>*/}
              {/*  <MDBDropdown>*/}
              {/*    <MDBDropdownToggle nav caret>*/}
              {/*      <div className="d-none d-md-inline">Dropdown</div>*/}
              {/*    </MDBDropdownToggle>*/}
              {/*    <MDBDropdownMenu className="dropdown-default">*/}
              {/*      <MDBDropdownItem href="#!">Action</MDBDropdownItem>*/}
              {/*      <MDBDropdownItem href="#!">Another Action</MDBDropdownItem>*/}
              {/*      <MDBDropdownItem href="#!">Something else here</MDBDropdownItem>*/}
              {/*      <MDBDropdownItem href="#!">Something else here</MDBDropdownItem>*/}
              {/*    </MDBDropdownMenu>*/}
              {/*  </MDBDropdown>*/}
              {/*</MDBNavItem>*/}
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBNavLink to="#!">{this.store.user.full_name}</MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
        <MDBContainer className={'mt-3'} fluid={true}>
          <Route path={'/'} exact name={'home'} onChangeMatch={this.onChangeMatch}>
            <HomeContainer/>
          </Route>
          <Route path={'/users'} name={'users'} onChangeMatch={this.onChangeMatch}>
            <UsersContainer/>
          </Route>
          <Route path={'/ads'} name={'ads'} onChangeMatch={this.onChangeMatch}>
            <AdsContainer/>
          </Route>
        </MDBContainer>
        <MDBFooter className={`font-small text-secondary mt-3 text-center py-3`}>
          <MDBContainer fluid>
            &copy; {moment().year()} <a rel={'noopener noreferrer'} target={'_blank'} className={'text-primary'} href="https://som.kg"> {APP_NAME} </a>
          </MDBContainer>
        </MDBFooter>
      </>
    );
  }
}

export default AppContainer;
