import User from "../models/User";

export const CategoryConverter = {
  from: (v) => {
    return v;
  },

  to: (v, store = null) => {
    return v;
  },
};

export const AdConverter = {
  from: (v) => {
    return v;
  },

  to: (v, store = null) => {
    if (store) {
      store.categoryStore.addOrUpdateItems(v.categories_path);
    }

    const dates = {};
    if (v.created_at) dates['created_at'] = new Date(v.created_at);
    if (v.updated_at) dates['updated_at'] = new Date(v.updated_at);
    if (v.author) dates['author'] = UserConverter.to(v.author, store);

    return {
      ...v,
      ...dates,
      raised_on: (v.raised_on || '').length > 0 ? new Date(v.raised_on) : null,
      published_on: (v.published_on || '').length > 0 ? new Date(v.published_on) : null,
    };
  },
};

export const UserConverter = {
  from: (v) => {
    return v;
  },

  to: (v, store = null) => {
    const user = User.create({
      ...v,
      is_agent: v.is_agent === '1',
      last_action: new Date(v.last_action),
      updated_at: new Date(v.updated_at),
      created_at: new Date(v.created_at),
    });

    if (store) {
      if (store.appStore.user.id !== user.id) store.userStore.addOrUpdateItem(user);
    }

    return user;
  },
};
