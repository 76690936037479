import CustomError from './CustomError';
import {API_URL_WITH_VERSION, axios_instance} from './settings';

export const getCustomError = (e) => {
  if (!(e instanceof CustomError)) e = new CustomError().setError(e);
  return e;
};

export const requester = {
  get: function (url, params = {}, silence = false){
    axios_instance._configs.silence = silence;
    return axios_instance.get(url, {params});
  },
  post: function (url, data, silence = false){
    axios_instance._configs.silence = silence;
    const form_data = new FormData();
    if (data) buildFormData(form_data, data);
    return axios_instance.post(`${API_URL_WITH_VERSION}${url}`, data ? form_data : {});
  },
  put: function (url, data, silence = false) {
    axios_instance._configs.silence = silence;
    return axios_instance.put(`${API_URL_WITH_VERSION}${url}`, data);
  },
  delete: function (url, params, silence = false) {
    axios_instance._configs.silence = silence;
    return axios_instance.delete(`${API_URL_WITH_VERSION}${url}`, {params});
  }
};

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    let value = data == null ? '' : data;
    if (value === true) value = "1";
    else if (value === false) value = "0";
    formData.append(parentKey, value);
  }
};

export const getParams = (url = window.location.href) => {
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  if (Object.keys(params).length === 1 && params[''] === 'undefined') return {};
  return params;
};
