import {getRoot, types as t} from 'mobx-state-tree';
import storage from '../utils/storage';
import CustomError from '../utils/CustomError';
import User from "../models/User";
import {requester} from "../utils";
import {UserConverter} from "../utils/converters";

export default t
  .model('AppStore', {

    authenticating: t.boolean,
    token: t.maybeNull(t.string),
    storage_data_fetched: t.boolean,
    user: t.maybeNull(t.reference(User)),

    error: t.maybeNull(t.custom({
      name: 'CustomError',
      fromSnapshot: (snapshot => new CustomError().fromSnapshot(snapshot)),
      toSnapshot: (value) => value.toSnapshot(),
      isTargetType: (value) => value instanceof CustomError,
      getValidationMessage: (value) => {
        return `'${value}' doesn't look like a CustomError object`;
      },
    })),

  })
  .actions(self => {

    const setValue = (name, value) => {
      self[name] = value;
    };

    const setToken = (s) => {
      self.token = s;
      storage.set('token', s);
    };

    const setUser = (s, save_to_storage = true) => {
      const user = UserConverter.to(s);
      getRoot(self).userStore.addOrUpdateItem(user);
      self.user = user.id;
      if (save_to_storage) storage.set('user', s);
    };

    const signOut = () => {
      storage.remove('token');
      storage.remove('user');

      self.user = null;
      self.token = null;
    };

    const clearError = () => {
      self.error = null;
    };
    const failed = (error) => {
      self.error = error;
    };

    const showMessage = (content, variant = 'default') => {
      alert(content);
    };

    const showError = (content) => {
      showMessage(content, 'error');
    };
    const showWarning = (content) => {
      showMessage(content, 'warning');
    };
    const showSuccess = (content) => {
      showMessage(content, 'success');
    };
    const showInfo = (content) => {
      showMessage(content, 'info');
    };

    const checkUser = async () => {
      if (self.authenticating) return;
      self.authenticating = true;
      try {
        const response = await requester.get('/user/get', {id: 0});
        if (response.status === 'success') {
          self.setUser(response.data, true);
        } else {
          self.signOut();
        }
      } catch (e) {
        console.log(e);
      } finally {
        self.setValue('authenticating', false);
      }
    };

    return {
      showError,
      showInfo,
      showWarning,
      showSuccess,
      clearError,
      failed,
      signOut,
      setValue,
      setToken,
      checkUser,
      setUser,
    };

  })
  .views(self => ({

    get error_occurred() {
      return self.error !== null;
    },

    get authenticated() {
      return self.user !== null;
    },

  }));
