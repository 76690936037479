import {types as t} from 'mobx-state-tree';
import AppStore from './AppStore';
import PlaceStore from './PlaceStore';
import CategoryStore from "./CategoryStore";
import AdStore from "./AdStore";
import UserStore from "./UserStore";

export default t
  .model('RootStore', {

    appStore: AppStore,
    placeStore: PlaceStore,
    categoryStore: CategoryStore,
    adStore: AdStore,
    userStore: UserStore,

  })
  .actions(self => ({

    setDataFromStorage(values) {
      if (values[0]) self.appStore.token = values[0];
      if (values[1]) self.appStore.setUser(values[1], false);
      if (values[2]) self.placeStore.setItems(values[2], false);
      if (values[3]) self.categoryStore.setItems(values[3], false);

      self.appStore.storage_data_fetched = true;
    },

  }))
  .create({

    appStore: AppStore.create({
      authenticating: false,
      checking: false,
      storage_data_fetched: false,
    }),
    placeStore: PlaceStore.create({items: []}),
    categoryStore: CategoryStore.create({items: {}}),
    adStore: AdStore.create({items: {}}),
    userStore: UserStore.create({items: {}}),

  });
