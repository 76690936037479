import React from 'react';
import BaseContainer from "./BaseContainer";
import abilityRequired from "../utils/abilityRequired";
import {
  MDBBadge, MDBBox, MDBBtn,
  MDBIcon,
  MDBNav, MDBNavItem, MDBNavLink,
  MDBPageItem, MDBPageNav,
  MDBPagination, MDBTable, MDBTableBody, MDBTableHead,
} from "mdbreact";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Loader from "../ui/Loader";
import moment from 'moment';
import {requester} from "../utils";
import AdModerateComponent from "../components/AdModerateComponent";

@abilityRequired('ads')
@inject('store') @observer
class AdsContainer extends BaseContainer {
  @observable current_page = 1;
  @observable current_tab = 'all';
  @observable sorting = 'desc';
  @observable order_by = 'created_at';

  constructor(props) {
    super(props);

    this.adStore.fetchCount();
    this.adStore.fetchItems();
  }

  @action toggle = tab => () => {
    if (this.current_tab !== tab) {
      this.adStore.setValue('items', {});
      this.adStore.fetchItems(tab === 'all' ? '' : tab, 1, this.order_by, this.sorting);
      this.current_page = 1;
      this.current_tab = tab;
    }
  };

  @action setSorting = (o, s) => {
    if (o === this.order_by && this.sorting === s) return;
    this.sorting = s;
    this.order_by = o;
    this.current_page = 1;
    this.adStore.setValue('items', {});
    this.adStore.fetchItems(
      this.current_tab === 'all' ? '' : this.current_tab,
      1, this.order_by, this.sorting,
    );
  };

  @action setPage = (v) => {
    const total_pages = Math.ceil(this.adStore.items_count[this.current_tab] / 30);
    if (v < 1 || v === this.current_page || v > total_pages) return;
    this.adStore.setValue('items', {});
    this.adStore.fetchItems(
      this.current_tab === 'all' ? '' : this.current_tab, v, this.order_by, this.sorting,
    );
    this.current_page = v;
  };

  delete = (item) => {
    if (item._busy || !window.confirm('Вы уверены?')) return;
    item.setValue('_busy', true);
    const {id} = item;

    requester.post('/admin/ad/delete', {id}).then(response => {
      if (response.status === 'success') {
        this.adStore.removeItem(id);
      }
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      item.setValue('_busy', false);
    });
  };

  moderate_modal_ref = React.createRef();

  render() {
    const total_pages = Math.ceil(this.adStore.items_count[this.current_tab] / 30);
    let pages_array = Array.from(Array(total_pages > 10 ? 10 : total_pages).keys());

    if (this.current_page > 6 && (total_pages - 4) > this.current_page) {
      pages_array = [];
      for (let i = 0; i < 10; i++) {
        pages_array.push(this.current_page - 6 + i);
      }
    } else if ((total_pages - 4) <= this.current_page && total_pages >= 4) {
      pages_array = [];
      for (let i = total_pages - 10; i < total_pages; i++) {
        pages_array.push(i);
      }
    }

    const items_array = (this.current_tab === 'all' ? Array.from(this.adStore.items.values()) : Array.from(this.adStore.items.values()).filter(v => v.status === this.current_tab));

    return (
      <>
        <AdModerateComponent getRef={r => this.moderate_modal_ref = r} />

        <MDBBox display={'flex'} justifyContent={'between'} alignItems={'center'}>
          <h2>Объявления</h2>
          <div>
            <select value={this.sorting} onChange={(e) => this.setSorting('created_at', e.target.value)}
                    className="browser-default custom-select">
              <option value="desc">Сортировать по убыванию</option>
              <option value="asc">Сортировать по возрастанию</option>
            </select>
          </div>
        </MDBBox>
        <MDBNav className="nav-tabs my-2">
          <MDBNavItem>
            <MDBNavLink disabled={this.adStore.fetching} link to="#" active={this.current_tab === 'all'}
                        onClick={this.toggle('all')} role="tab">
              Все{this.adStore.items_count.all === null ? null :
              <MDBBadge pill color={'light'} className={'ml-2'}>{this.adStore.items_count.all}</MDBBadge>}
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink disabled={this.adStore.fetching} link to="#" active={this.current_tab === 'pending'}
                        onClick={this.toggle('pending')} role="tab">
              В ожидании{this.adStore.items_count.pending === null ? null :
              <MDBBadge pill color={'success'} className={'ml-2'}>{this.adStore.items_count.pending}</MDBBadge>}
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink disabled={this.adStore.fetching} link to="#" active={this.current_tab === 'published'}
                        onClick={this.toggle('published')}
                        role="tab">
              Опубликованные{this.adStore.items_count.published === null ? null :
              <MDBBadge pill color={'light'} className={'ml-2'}>{this.adStore.items_count.published}</MDBBadge>}
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink disabled={this.adStore.fetching} link to="#" active={this.current_tab === 'sold'}
                        onClick={this.toggle('sold')} role="tab">
              Проданные{this.adStore.items_count.sold === null ? null :
              <MDBBadge pill color={'light'} className={'ml-2'}>{this.adStore.items_count.sold}</MDBBadge>}
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink disabled={this.adStore.fetching} link to="#" active={this.current_tab === 'rejected'}
                        onClick={this.toggle('rejected')}
                        role="tab">
              Отклоненные{this.adStore.items_count.rejected === null ? null :
              <MDBBadge pill color={'light'} className={'ml-2'}>{this.adStore.items_count.rejected}</MDBBadge>}
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        {this.adStore.fetching ? (
          <MDBBox className={'my-5'} display={'flex'} justifyContent={'center'}>
            <Loader/>
          </MDBBox>
        ) : (
          <MDBBox className={'my-2'}>
            <MDBTable striped>
              <MDBTableHead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Автор</th>
                  <th>Категория</th>
                  <th>Отправлено</th>
                  <th>Действия</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {items_array.length > 0 ? (
                  <>
                    {items_array.map((v, key) => {
                      let title_color = 'dark';
                      if (v.status === 'pending') title_color = 'warning';
                      else if (v.status === 'rejected') title_color = 'danger';
                      const title_content = v.title_ru === v.title_ky ? v.title_ky : <>{v.title_ru}<br/>{v.title_ky}</>;
                      return (
                        <>
                          <tr key={key}>
                            <td>{v.id}</td>
                            <td className={`text-${title_color} font-weight-bold`}
                                style={{fontSize: '1rem'}}>{title_content}</td>
                            <td>
                              <a className={'text-primary'} rel={'noopener noreferrer'} target={'_blank'}
                                 href={`https://som.kg/users/view/${v.author.id}`}>{v.author.full_name}</a>
                            </td>
                            <td>{v.categories_path.slice().reverse().map(c => c.title_ru).join(' > ')}</td>
                            <td>{moment(v.created_at).calendar()}</td>
                            <td>
                              <MDBBtn disabled={v._busy} href={`https://som.kg/ads/view/${v.id}`} size={'sm'} target="_blank"
                                      color="primary">
                                Подробнее
                              </MDBBtn>
                              <MDBBtn disabled={v._busy} onClick={() => this.moderate_modal_ref.modalToggle(v.id)} size={'sm'}
                                      color="secondary">
                                Модерировать
                              </MDBBtn>
                              <MDBBtn disabled={v._busy} onClick={() => this.delete(v)} size={'sm'}
                                      color="danger" style={{margin: 0}}>
                                <MDBIcon icon="trash"/>
                              </MDBBtn>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <p className={'text-center my-3'}>Список пуст.</p>
                    </td>
                  </tr>
                )}
              </MDBTableBody>
            </MDBTable>
          </MDBBox>
        )}
        {total_pages > 0 && (
          <MDBBox justifyContent={'center'} className={'mt-2'} display={'flex'}>
            <MDBPagination circle>
              <MDBPageItem disabled={this.current_page === 1 || this.adStore.fetching}>
                <MDBPageNav onClick={() => this.setPage(this.current_page - 1)} className="page-link"
                            aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              {pages_array.map((v, key) => (
                <MDBPageItem disabled={this.adStore.fetching} key={key} active={this.current_page === (v + 1)}>
                  <MDBPageNav onClick={() => this.setPage(v + 1)} className="page-link">
                    {v + 1} <span className="sr-only">(current)</span>
                  </MDBPageNav>
                </MDBPageItem>
              ))}
              <MDBPageItem disabled={this.current_page === total_pages || this.adStore.fetching}>
                <MDBPageNav onClick={() => this.setPage(this.current_page + 1)} className="page-link">
                  &raquo;
                </MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </MDBBox>
        )}
      </>
    );
  }
}

export default AdsContainer;
