import {types as t} from 'mobx-state-tree';
import storage from '../utils/storage';
import Category from "../models/Category";
import {requester} from "../utils";
import {values} from "mobx";

export default t
  .model('CategoryStore', {

    items: t.map(Category),
    fetching: t.optional(t.boolean, false),

  })
  .actions(self => ({

    setValue(name, value) {
      self[name] = value;
    },

    setItems(p, save_to_storage= true) {
      self.createOrUpdate(p);
      if (save_to_storage) {
        storage.set('categories', p);
      }
    },

    addOrUpdateItems(v) {
      v.map((p) => self.items.set(p.id, p));
    },

    createOrUpdate(item) {
      if (Array.isArray(item)) {
        item.map((v) => self.createOrUpdate(v));
        return;
      }

      if ('children' in item) {
        self.createOrUpdate(item.children);
      }

      self.items.set(item.id, {
        ...item,
      });
    },

    fetchItems() {
      if (self.fetching) return;
      self.fetching = true;
      requester
        .get("/category/get")
        .then(({data}) => {
          self.createOrUpdate(data.data);
          storage.set('categories', data.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          self.setValue('fetching', false);
        });
    },

  }))
  .views(self => ({
    get main() {
      return values(self.items).filter(v => !v.parent);
    },
  }));
