import {types as t} from 'mobx-state-tree';

const Category = t
  .model('Category', {
    id: t.identifierNumber,
    title_ru: t.string,
    title_ky: t.string,
    icon: t.maybeNull(t.string),
    ads_count: t.integer,
    forms: t.array(t.string),
    parent: t.maybeNull(t.integer),
  })
  .views(self => ({

  }))
  .actions(self => ({}));

export default Category;
