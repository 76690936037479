import React from 'react';

class Loader extends React.Component {
  render() {
    return (
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Подождите...</span>
      </div>
    );
  }
}

export default Loader;
