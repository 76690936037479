import React from 'react';
import BaseComponent from "./BaseComponent";
import abilityRequired from "../utils/abilityRequired";
import {inject, observer} from "mobx-react";
import {action, observable, values} from "mobx";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";

@abilityRequired('ads')
@inject('store') @observer
class CategorySelectorComponent extends BaseComponent {
  @observable tree = [null];

  @action onSelect = (id, i) => {
    const selected = this.categoryStore.items.get(id);
    this.tree.splice(i + 1);
    if (selected) {
      if (values(this.categoryStore.items).filter(g => g.parent === +id).length > 0) {
        this.tree.push(id);
      } else {
        this.props.onChange([
          this.categoryStore.items.get(id),
          ...this.tree.filter(v => this.categoryStore.items.get(v)).reverse().map(v => this.categoryStore.items.get(v)),
        ]);
      }
    }
  };

  render() {
    return (
      <>
        <MDBContainer className={'mt-2'}>
          <MDBRow>
            {this.tree.map((v, i) => (
              <MDBCol size={4}>
                <select value={this.tree[i + 1] || null} onChange={(e) => this.onSelect(e.target.value ? +e.target.value : null, i)}
                  className="browser-default custom-select">
                  <option value={null}>Выберите категорию</option>
                  {values(this.categoryStore.items).filter(g => g.parent === (v ? +v : null)).map(g => (
                    <option value={g.id}>{g.title_ru}</option>
                  ))}
                </select>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
}

export default CategorySelectorComponent;
