import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import "./assets/scss/mdb-free.scss"
import AppContainer from "./containers/AppContainer";
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "mobx-react";
import RootStore from "./stores/RootStore";

class Root extends React.Component {
  render() {
    return (
      <Provider store={RootStore}>
        <BrowserRouter>
          <AppContainer/>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Root;
