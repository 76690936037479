import {getRoot, types as t} from 'mobx-state-tree';
import Ad from "../models/Ad";
import {requester} from "../utils";
import {AdConverter} from "../utils/converters";

export default t
  .model('AdStore', {

    items: t.map(Ad),
    fetching: t.optional(t.boolean, false),
    fetching_count: t.optional(t.boolean, false),
    items_count: t.optional(t.frozen(), {
      all: null, pending: null, sold: null, published: null, rejected: null,
    }),

  })
  .actions(self => ({

    setValue(name, value) {
      self[name] = value;
    },

    setItems(p) {
      p.map(v => self.items.set(v.id, v));
    },

    addOrUpdateItem(v) {
      self.items.set(v.id, v);
    },

    removeItem(id) {
      self.items.delete(id);
    },

    fetchItems(status = "", page = 1, order_by = 'created_at', sorting = 'desc') {
      if (self.fetching) return;
      self.fetching = true;
      const root = getRoot(self);
      requester
        .get("/admin/ad/items_by_status", {
          page,
          limit: 30,
          status,
          order_by,
          sort_by: sorting,
        })
        .then((data) => {
          if (data.status === "success") self.setItems(data.data.map(v => AdConverter.to(v, root)));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          self.setValue('fetching', false);
        });
    },

    fetchCount() {
      if (self.fetching_count) return;
      self.fetching_count = true;
      requester.get('/admin/ad/items_count').then((data) => {
        if (data.status === 'success') self.setValue('items_count', data.data);
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        self.setValue('fetching_count', false);
      });
    }

  }))
  .views(self => ({

  }));
