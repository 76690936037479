import {types as t} from 'mobx-state-tree';
import User from "../models/User";

export default t
  .model('UserStore', {

    items: t.map(User),

  })
  .actions(self => ({

    setValue(name, value) {
      self[name] = value;
    },

    setItems(p) {
      self.items = p;
    },

    addOrUpdateItems(v) {
      v.map((p) => self.items.set(p.id, p));
    },

    addOrUpdateItem(v) {
      self.items.set(v.id, v);
    },

  }))
  .views(self => ({

  }));
