import React from 'react';
import {inject, observer} from 'mobx-react';

const render_ui = <p>Доступ закрыт!</p>;

@inject('store') @observer
class AbilityRequired extends React.Component {
  render() {
    const {authProps, store, ...rest} = this.props;
    const {user} = store.appStore;
    const {Component, ability} = authProps;

    if (!user || (ability === null && !user.is_admin)) {
      return render_ui;
    } else if (!user.is_admin || (!user.abilities.includes(ability) && !user.abilities.includes('admin'))) {
      return render_ui;
    }

    return <Component {...rest}/>;
  }
}

export default (ability = null) => {
  return Component => {
    return props => <AbilityRequired {...props} authProps={{Component, ability}}/>;
  };
};
