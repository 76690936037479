import {types as t} from 'mobx-state-tree';

const User = t
  .model('User', {
    id: t.identifierNumber,
    provider: t.string,
    provider_id: t.string,
    provider_data: t.maybeNull(t.frozen()),
    email: t.maybeNull(t.string),
    full_name: t.string,
    bio: t.maybeNull(t.string),
    //phone_1
    //phone_2
    //phone_3
    picture: t.maybeNull(t.string),
    last_action: t.Date,
    balance: t.maybeNull(t.integer),
    abilities: t.array(t.string),
    language: t.maybeNull(t.enumeration('language', ['ru', 'ky'])),
    is_agent: t.boolean,
    created_at: t.Date,
    updated_at: t.Date,
  })
  .views(self => ({
    is_admin: () => {
      return self.abilities.length > 0;
    },
  }))
  .actions(self => ({}));

export default User;
