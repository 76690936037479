import {types as t} from 'mobx-state-tree';
import Category from "./Category";
import User from "./User";
import {requester} from "../utils";

const Ad = t
  .model('Ad', {
    id: t.identifierNumber,
    author: t.reference(User),
    categories_path: t.array(Category),
    published_by: t.maybeNull(t.reference(User)),
    title_ru: t.string,
    title_ky: t.string,
    description_ru: t.maybeNull(t.string),
    description_ky: t.maybeNull(t.string),
    //phone_1
    //phone_2
    //phone_3
    images: t.frozen(),
    status: t.enumeration('status', ['published', 'rejected', 'pending', 'sold']),
    indexing: t.maybeNull(t.string),
    keywords: t.maybeNull(t.string),
    raised_on: t.maybeNull(t.Date),
    published_on: t.maybeNull(t.Date),
    created_at: t.maybeNull(t.Date),
    updated_at: t.maybeNull(t.Date),

    _busy: t.optional(t.boolean, false),
    _fetching: t.optional(t.boolean, false),
  })
  .views(self => ({
    get category() {
      if (self.categories_path.length === 0) return null;
      return self.categories_path[0];
    },
  }))
  .actions(self => ({

    setValue(name, value) {
      self[name] = value;
    },

    async fetch() {
      if (self._fetching) return false;
      self._fetching = true;
      try {
        const data = await requester.get('/ad/get_by_id', {id: self.id})
        if (data.status === 'success') {
          self.setValue('description_ru', data.data.description_ru);
          self.setValue('description_ky', data.data.description_ky);
          return data.data;
        }
      } catch (e) {
        console.log(e);
      } finally {
        self.setValue('_fetching', false);
      }
    },

    publish() {
      if (self._busy || self.status === 'published') return;
      self._busy = true;
      requester.post('/').then((data) => {
        console.log(data);
      }).catch((e) => {
        console.log(e);
        alert('Ошибка!');
      }).finally(() => {
        self.setValue('_busy', false);
      });
    },

  }));

export default Ad;
