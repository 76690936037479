class CustomError extends Error {
  description = 'Белгисиз катачылык!';
  title = 'Катачылык!';
  status = -1;
  payload = '';
  result = 'unknown_error';
  retryFunc = null;
  error = null;

  constructor(response = null, retryFunc = null) {
    super();
    if (response) this.setResponse(response);
    this.setRetryFunc(retryFunc);
  }

  setError(error) {
    if (error.response) {
      this.setResponse(error.response);
    } else {
      this.description = error.message === 'Network Error' ? 'Интернетке туташуудан катачылык!' : error.message;
    }
    this.error = error;
    return this;
  }

  setRetryFunc = (func) => {
    this.retryFunc = func;
    return this;
  };

  setDescription = (string) => {
    this.description = string;
    return this;
  };

  setTitle = (string) => {
    this.title = string;
    return this;
  };

  toSnapshot = () => {
    return {
      description: this.description,
      title: this.title,
      status: this.status,
      payload: this.payload,
      result: this.result,
      retryFunc: this.retryFunc,
      error: this.error,
    };
  };

  fromSnapshot = (snapshot) => {
    this.description = snapshot.description;
    this.title = snapshot.title;
    this.status = snapshot.status;
    this.payload = snapshot.payload;
    this.result = snapshot.result;
    this.retryFunc = snapshot.retryFunc;
    this.error = snapshot.error;
  };

  setResponse = ({data}) => {
    this.payload = data.payload;
    this.result = data.result;
    this.status = data.status;
    this.description = data.payload;

    if (data.status < 0) {
      if (data.result === 'invalid_params') this.description = 'Параметрлер туура эмес!';
    }

    return this;
  };
}

export default CustomError;
