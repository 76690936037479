import React from 'react';
import {Route as RRoute} from 'react-router-dom';

class Route extends React.Component {
  render() {
    const {props} = this;

    return (
      <RRoute path={props.path} exact={props.exact || false} render={({match}) => {
        if (match) props.onChangeMatch(props.name);
        return props.children;
      }}/>
    );
  }
}

export default Route;
