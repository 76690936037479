import {types as t} from 'mobx-state-tree';

const Place = t
  .model('Place', {
    id: t.identifierNumber,
    title: t.string,
    parent_id: t.maybeNull(t.integer),
    position: t.maybeNull(t.integer),
  })
  .views(self => ({}))
  .actions(self => ({}));

export default Place;
