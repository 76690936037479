import {types as t} from 'mobx-state-tree';
import storage from '../utils/storage';
import Place from '../models/Place';

export default t
  .model('PlaceStore', {

    items: t.array(Place),

  })
  .actions(self => ({

    setValue(name, value) {
      self[name] = value;
    },

    setItems(p, save_to_storage = true) {
      self.items = p;
      if (save_to_storage) {
        storage.set('places', p);
      }
    },

  }))
  .views(self => ({

  }));
