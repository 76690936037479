import React from 'react';
import BaseContainer from "./BaseContainer";

class HomeContainer extends BaseContainer {
  render() {
    return (
      <p>
        Home Контент
      </p>
    );
  }
}

export default HomeContainer;
