import React from 'react';
import BaseComponent from "./BaseComponent";
import abilityRequired from "../utils/abilityRequired";
import {
  MDBBtn, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle,
  MDBIcon, MDBInput, MDBInputGroup, MDBModal,
  MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow,
} from "mdbreact";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Loader from "../ui/Loader";
import {getSnapshot} from "mobx-state-tree";
import {requester} from "../utils";
import CategorySelectorComponent from "./CategorySelectorComponent";
import moment from "moment";

class PriceQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex'}>
        <MDBCol>
          <MDBInputGroup
            hint={'Цена'}
            type={'number'}
            value={!value ? '' : value.price}
            onChange={({target}) => onChange(target.value && !isNaN(+target.value) && +target.value > 0 ? {
              currency: value ? value.currency : 'сом',
              price: target.value,
            } : null)}
            append={
              <MDBDropdown>
                <MDBDropdownToggle
                  color="white"
                  size="md"
                  className="m-0 px-3 z-depth-0"
                  style={{
                    borderTop: '1px solid #ced4da',
                    borderBottom: '1px solid #ced4da',
                    borderRight: '1px solid #ced4da',
                    height: 38,
                    borderRadius: '.25rem',
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                >
                  {value && value.currency ? value.currency : 'СОМ'} <MDBIcon icon="caret-down" className="ml-1"/>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    disabled={!value}
                    onClick={() => onChange({
                      currency: 'сом',
                      price: value ? value.price : '',
                    })}>СОМ</MDBDropdownItem>
                  <MDBDropdownItem
                    disabled={!value}
                    onClick={() => onChange({
                      currency: 'доллар',
                      price: value ? value.price : '',
                    })}>ДОЛЛАР</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            }
          />
        </MDBCol>
        <MDBCol>
          <MDBBtn className={'btn-block'} onClick={() => onChange(null)} size={'sm'} outline={value && value.price.length > 0} disabled={!value || value.price.length === 0}>Договорная</MDBBtn>
        </MDBCol>
      </MDBRow>
    );
  }
}

class WageOfVacancyQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          <MDBInputGroup
            hint={'Заработная плата'}
            type={'number'}
            value={!value ? '' : value.price}
            onChange={({target}) => onChange(target.value && !isNaN(+target.value) && +target.value > 0 ? {
              currency: value ? value.currency : 'сом',
              price: target.value,
            } : null)}
            append={
              <MDBDropdown>
                <MDBDropdownToggle
                  color="white"
                  size="md"
                  className="m-0 px-3 z-depth-0"
                  style={{
                    borderTop: '1px solid #ced4da',
                    borderBottom: '1px solid #ced4da',
                    borderRight: '1px solid #ced4da',
                    height: 38,
                    borderRadius: '.25rem',
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                >
                  {value && value.currency ? value.currency : 'СОМ'} <MDBIcon icon="caret-down" className="ml-1"/>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    disabled={!value}
                    onClick={() => onChange({
                      currency: 'сом',
                      price: value ? value.price : '',
                    })}>СОМ</MDBDropdownItem>
                  <MDBDropdownItem
                    disabled={!value}
                    onClick={() => onChange({
                      currency: 'доллар',
                      price: value ? value.price : '',
                    })}>ДОЛЛАР</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            }
          />
        </MDBCol>
        <MDBCol>
          <MDBBtn className={'btn-block'} onClick={() => onChange(null)} size={'sm'} outline={value && value.price.length > 0} disabled={!value || value.price.length === 0}>Договорная</MDBBtn>
        </MDBCol>
      </MDBRow>
    );
  }
}

class StateQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Состояние
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            <option value="new">Новый</option>
            <option value="used">Б/У</option>
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class RoomsQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Количество комнат
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6+">6+</option>
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class FurnitureQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Мебель
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            <option value="1">Есть</option>
            <option value="0">Нет</option>
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class AgencyOrOwnerQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Агентство недвижимости или собственник?
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            <option value="agency">Агентство</option>
            <option value="owner">Собственник</option>
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class PhoneMakesQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Марка телефона
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              'Samsung',
              'Apple iPhone',
              'Xiaomi',
              'Huawei',
              'Lenovo',
              'Philips',
              'Nokia',
              'Sony',
              '',
            ].map(v => (
              <option value={v}>{v || 'Другое'}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class ExperienceOfVacancyQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Опыт работы
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {code: 1, title: 'не имеет значения'},
              {code: 2, title: 'более 1 года'},
              {code: 3, title: 'более 3 лет'},
              {code: 4, title: 'более 5 лет'},
              {code: 5, title:'более 10 лет'},
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class ScheduleOfVacancyQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          График работы
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {code: 1, title: 'вахтовый метод'},
              {code: 2, title: 'неполный день'},
              {code: 3, title: 'полный день'},
              {code: 4, title: 'свободный график'},
              {code: 5, title: 'сменный график'},
              {code: 6, title: 'удалённая работа'},
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class VacancyQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Вакансия
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {code: 1, title: 'автомойщик'},
              {code: 2, title: 'официант'},
              {code: 3, title: 'охранник'},
              {code: 4, title: 'няня'},
              {code: 5, title: 'электрик'},
              {code: 6, title: 'бухгалтер'},
              {code: 7, title: 'водитель'},
              {code: 8, title: 'повар'},
              {code: 9, title: 'горничная'},
              {code: 10, title: 'грузчик'},
              {code: 11, title: 'швея'},
              {code: 12, title: 'администратор'},
              {code: 13, title: 'бариста'},
              {code: 14, title: 'бармен'},
              {code: 15, title: 'кладовщик'},
              {code: 16, title: 'курьер'},
              {code: 17, title: 'мастер маникюра'},
              {code: 18, title: 'машинист экскаватора'},
              {code: 19, title: 'менеджер'},
              {code: 20, title: 'монтажник'},
              {code: 21, title: 'оператор'},
              {code: 22, title: 'парикмахер'},
              {code: 23, title: 'продавец-кассир'},
              {code: 24, title: 'промоутер'},
              {code: 25, title: 'разнорабочий'},
              {code: 26, title: 'сварщик'},
              {code: 27, title: 'сиделка'},
              {code: 28, title: 'сторож'},
              {code: 29, title: 'торговый представитель'},
              {code: 30, title: 'уборщица'},
              {code: 31, title: 'флорист'},
              {code: 32, title: 'другие специальности'},
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class YearOfIssueQuestion extends BaseComponent {
  constructor(props) {
    super(props);
    this.year = moment().year();
  }

  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Год выпуска
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {Array.from(Array(40).keys()).map(v => (
              <option value={this.year - v}>{this.year - v}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

export const CAR_MAKES = {
  'Toyota': [
    '4Runner',
    'Avalon',
    'Avensis',
    'Avensis',
    'Avensis',
    'Avensis',
    'Highlander',
    'Ipsum',
    'Ist',
    'Land Cruiser',
    'Land Cruiser Prado',
    'Mark II',
    'Prius',
    'RAV4',
    'Sequoia',
    'Windom',
    'Wish',
    '',
  ],
  'Honda': [
    'Accord',
    'Civic',
    'CR-V',
    'Fit',
    'Inspire',
    'Jazz',
    'Odyssey',
    'StepWGN',
    'Stream',
    '',
  ],
  'Lexus': [
    'ES',
    'GS',
    'GX',
    'LS',
    'LX',
    'RX',
    '',
  ],
  'Mercedes-Benz': [
    'C-Class',
    'E-Class',
    'G-Class',
    'GL-Class',
    'M-Class',
    'S-Class',
    'Vito',
    '',
  ],
  'Hyundai': [
    'Avante',
    'Getz',
    'Porter',
    'Solaris',
    'Sonata',
    'Starex',
    '',
  ],
  'BMW': [
    '3-Series',
    '5-Series',
    '7-Series',
    'X5',
    'X6',
    '',
  ],
  'Daewoo': [
    'Labo',
    'Lacetti',
    'Lanos',
    'Matiz',
    'Nexia',
    'Nubira',
    'Tico',
    '',
  ],
  'Opel': [
    'Astra',
    'Vectra',
    'Zafira',
    '',
  ],
  'Volkswagen': [
    'Golf',
    'Jetta',
    'Passat',
    'Transporter',
    'Vento',
    '',
  ],
  'Lada': [
    '',
  ],
  'Audi': [
    '',
  ],
  'Mazda': [
    '323',
    '626',
    'Demio',
    'MPV',
    'Premacy',
    '',
  ],
  'Nissan': [
    'Almera',
    'Cefiro',
    'Pathfinder',
    'Patrol',
    'Primera',
    'X-Trail',
    '',
  ],
  'Mitsubishi': [
    'Delica',
    'Galant',
    'Lancer',
    'Pajero',
    'Space Wagon',
    '',
  ],
  'Ford': [
    'Focus',
    'Mondeo',
    ''
  ],
  'Porsche': [
    '',
  ],
  'Renault': [
    '',
  ],
  'Kia': [
    '',
  ],
  'Chrysler': [
    '',
  ],
  'Cadillac': [
    '',
  ],
  'Chevrolet': [
    '',
  ],
  'Infiniti': [
    '',
  ],
  'Peugeot': [
    '',
  ],
  'Rover': [
    '',
  ],
  'Skoda': [
    '',
  ],
  '': [
    '',
  ],
};

class CarMakeQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Марка
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {Object.keys(CAR_MAKES).map(v => (
              <option value={v}>{v || 'Другие марки'}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class CarModelQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Модель
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {CAR_MAKES[this.props.carMake].map(v => (
              <option value={v}>{v || 'Другое'}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class GearTypeQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Тип передачи
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {name: 'automatic', value: 'автоматический'},
              {name: 'mechanics', value: 'механический'},
            ].map(v => (
              <option value={v.name}>{v.value}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class FuelQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Топливо
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {name: 'petrol', value: 'бензин'},
              {name: 'diesel', value: 'дизель'},
              {name: 'gas', value: 'газ'},
              {name: 'petrol+gas', value: 'бензин+газ'},
            ].map(v => (
              <option value={v.name}>{v.value}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class SummaryQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Резюме
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {
                "code": 1,
                "title": "Производство/энергетика",
              },
              {
                "code": 2,
                "title": "Бары/рестораны/гостиницы",
              },
              {
                "code": 3,
                "title": "Розничная торговля/продажи",
              },
              {
                "code": 4,
                "title": "Строительство",
              },
              {
                "code": 5,
                "title": "Транспорт/логистика",
              },
              {
                "code": 6,
                "title": "Сервис и быт",
              },
              {
                "code": 7,
                "title": "Образование",
              },
              {
                "code": 8,
                "title": "Электрики/сантехники",
              },
              {
                "code": 9,
                "title": "Перевод",
              },
              {
                "code": 10,
                "title": "Стоматология",
              },
              {
                "code": 11,
                "title": "Разнорабочие",
              },
              {
                "code": 12,
                "title": "Юриспруденция",
              },
              {
                "code": 13,
                "title": "Бухгалтерия/финансы",
              },
              {
                "code": 14,
                "title": "IТ/телеком/компьютеры",
              },
              {
                "code": 15,
                "title": "Красота/фитнес/спорт",
              },
              {
                "code": 16,
                "title": "Домашний персонал/уборка",
              },
              {
                "code": 17,
                "title": "Медицина/фармацевтика",
              },
              {
                "code": 18,
                "title": "Работа за границей",
              },
              {
                "code": 19,
                "title": "Управление персоналом",
              },
              {
                "code": 20,
                "title": "Автомобильный бизнес",
              },
              {
                "code": 21,
                "title": "Маркетинг/реклама/дизайн",
              },
              {
                "code": 22,
                "title": "Швейное дело",
              },
              {
                "code": 23,
                "title": "Банки/страхование",
              },
              {
                "code": 24,
                "title": "Секретариат/госслужба",
              },
              {
                "code": 25,
                "title": "Туризм/отдых/развлечение",
              },
              {
                "code": 26,
                "title": "Начало карьеры/студенты",
              },
              {
                "code": 27,
                "title": "Охрана/безопасность",
              },
              {
                "code": 28,
                "title": "Культура/искусство",
              },
              {
                "code": 29,
                "title": "Другие специальности",
              },
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class EducationOfSummaryQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Образование
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {code: 1, title: 'высшее'},
              {code: 2, title: 'незаконченное высшее'},
              {code: 3, title: 'среднее'},
              {code: 4, title: 'среднее специальное'},
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class GenderQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Пол
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {[
              {code: 1, title: 'женский'},
              {code: 2, title: 'мужской'},
            ].map(v => (
              <option value={v.code}>{v.title}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class AgeOfSummaryQuestion extends BaseComponent {
  render() {
    const {value, onChange} = this.props;

    return (
      <MDBRow className={'justify-content-center align-items-center d-flex mt-3'}>
        <MDBCol>
          Возраст
        </MDBCol>
        <MDBCol>
          <select value={value}
                  onChange={({target}) => onChange(target.value)}
                  className="browser-default custom-select">
            <option value="" selected>Нет данных</option>
            {Array.from(Array(83).keys()).map(v => (
              <option value={v + 18}>{v + 18}</option>
            ))}
          </select>
        </MDBCol>
      </MDBRow>
    );
  }
}

class AdQuestions extends BaseComponent {
  render() {
    const {data, questions} = this.props;
    const additional = data.additionals;

    if (!additional) return null;
    let result = [];

    if (questions.includes('price')) {
      result.push(<PriceQuestion onChange={(value) => this.props.onChange('price', value)}
                                 value={'price' in additional ? additional['price'] : null}/>);
    }

    if (questions.includes('state')) {
      result.push(<StateQuestion onChange={(value) => this.props.onChange('state', value)}
                                 value={'state' in additional ? additional['state'] : null}/>);
    }

    if (questions.includes('rooms')) {
      result.push(<RoomsQuestion onChange={(value) => this.props.onChange('rooms', value)}
                                 value={'rooms' in additional ? additional['rooms'] : null}/>);
    }

    if (questions.includes('furniture')) {
      result.push(<FurnitureQuestion onChange={(value) => this.props.onChange('furniture', value)}
                                     value={'furniture' in additional ? additional['furniture'] : null}/>);
    }

    if (questions.includes('agency_or_owner')) {
      result.push(<AgencyOrOwnerQuestion onChange={(value) => this.props.onChange('agency_or_owner', value)}
                                         value={'agency_or_owner' in additional ? additional['agency_or_owner'] : null}/>);
    }

    if (questions.includes('phone_make')) {
      result.push(<PhoneMakesQuestion onChange={(value) => this.props.onChange('phone_make', value)}
                                         value={'phone_make' in additional ? additional['phone_make'] : null}/>);
    }

    if (questions.includes('vacancy')) {
      result.push(<VacancyQuestion onChange={(value) => this.props.onChange('vacancy', value)}
                                             value={'vacancy' in additional ? additional['vacancy'] : null}/>);
    }

    if (questions.includes('schedule_of_vacancy')) {
      result.push(<ScheduleOfVacancyQuestion onChange={(value) => this.props.onChange('schedule_of_vacancy', value)}
                                             value={'schedule_of_vacancy' in additional ? additional['schedule_of_vacancy'] : null}/>);
    }

    if (questions.includes('experience_of_vacancy')) {
      result.push(<ExperienceOfVacancyQuestion onChange={(value) => this.props.onChange('experience_of_vacancy', value)}
                                               value={'experience_of_vacancy' in additional ? additional['experience_of_vacancy'] : null}/>);
    }

    if (questions.includes('wage_of_vacancy')) {
      result.push(<WageOfVacancyQuestion onChange={(value) => this.props.onChange('wage_of_vacancy', value)}
                                         value={'wage_of_vacancy' in additional ? additional['wage_of_vacancy'] : null}/>);
    }

    if (questions.includes('year_of_issue')) {
      result.push(<YearOfIssueQuestion onChange={(value) => this.props.onChange('year_of_issue', value)}
                                       value={'year_of_issue' in additional ? additional['year_of_issue'] : null}/>);
    }

    if (questions.includes('car_make')) {
      result.push(<CarMakeQuestion onChange={(value) => this.props.onChange('car_make', value)}
                                   value={'car_make' in additional ? additional['car_make'] : null}/>);
    }

    if (questions.includes('car_model') && 'car_make' in additional && additional['car_make']) {
      result.push(<CarModelQuestion carMake={additional['car_make']} onChange={(value) => this.props.onChange('car_model', value)}
                                    value={'car_model' in additional ? additional['car_model'] : null}/>);
    }

    if (questions.includes('gear_type')) {
      result.push(<GearTypeQuestion onChange={(value) => this.props.onChange('gear_type', value)}
                                    value={'gear_type' in additional ? additional['gear_type'] : null}/>);
    }

    if (questions.includes('fuel')) {
      result.push(<FuelQuestion onChange={(value) => this.props.onChange('fuel', value)}
                                value={'fuel' in additional ? additional['fuel'] : null}/>);
    }

    if (questions.includes('summary')) {
      result.push(<SummaryQuestion onChange={(value) => this.props.onChange('summary', value)}
                                   value={'summary' in additional ? additional['summary'] : null}/>);
    }

    if (questions.includes('education_of_summary')) {
      result.push(<EducationOfSummaryQuestion onChange={(value) => this.props.onChange('education_of_summary', value)}
                                              value={'education_of_summary' in additional ? additional['education_of_summary'] : null}/>);
    }

    if (questions.includes('gender')) {
      result.push(<GenderQuestion onChange={(value) => this.props.onChange('gender', value)}
                                  value={'gender' in additional ? additional['gender'] : null}/>);
    }

    if (questions.includes('gender')) {
      result.push(<AgeOfSummaryQuestion onChange={(value) => this.props.onChange('age_of_summary', value)}
                                  value={'age_of_summary' in additional ? additional['age_of_summary'] : null}/>);
    }

    return result;
  }
}

@abilityRequired('ads')
@inject('store') @observer
class AdModerateComponent extends BaseComponent {
  @observable modal_visible = false;
  @observable modal_item = null;

  @observable modal_data = {};

  @action modalToggle = async (id = null) => {
    this.modal_visible = !this.modal_visible;
    this.modal_item = this.adStore.items.has(id) ? this.adStore.items.get(id) : null;
    if (this.modal_visible && this.modal_item) {
      this.modal_data = getSnapshot(this.modal_item);
      this.modal_data = await this.modal_item.fetch();
    } else {
      this.modal_data = {};
    }
  };

  @action setModalData(name, value) {
    this.modal_data[name] = value;
  }

  @action
  async setStatus(s) {
    if (
      !this.modal_visible || !this.modal_item ||
      this.modal_item.status.startsWith(s) ||
      this.modal_item._busy ||
      this.modal_data === {} || !['reject', 'publish'].includes(s)
    ) return;
    this.modal_item.setValue('_busy', true);
    let other_params = s === 'reject' ? {} : {
      description_ru: this.modal_data.description_ru,
      description_ky: this.modal_data.description_ky,
      title_ru: this.modal_data.title_ru,
      title_ky: this.modal_data.title_ky,
      keywords: this.modal_data.keywords,
      category_id: this.modal_data.categories_path[0].id,
      additional: {
        ...this.modal_data.additionals,
        place: {
          ...this.modal_data.additionals.place,
          children: null,
        },
      },
    };
    try {
      const data = await requester.post(`/admin/ad/${s}`, {
        id: this.modal_item.id,
        ...other_params,
      });
      if (data.status === "success") {
        Object.keys(data.data.item).map(v => {
          if (['created_at', 'updated_at', 'raised_on', 'published_on'].includes(v)) data.data.item[v] = new Date(data.data.item[v]);
          this.modal_item.setValue(v, data.data.item[v]);
        });
        this.modal_data = {};
        this.modal_visible = false;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.modal_item.setValue('_busy', false);
    }
  }

  deleteImage = (id, url) => {
    url = url.split('/');
    url[8] = `tabekg_${url[8].split('_tabekg_')[1]}`;
    if (!window.confirm('Вы уверены?')) return;
    requester.post('/ad/delete_image', {
      id, url: url.join('/'),
    }).then(res => {
      this.setModalData('images', res.data);
    }).catch(e => {
      console.log(e);
    });
  };

  constructor(props) {
    super(props);

    this.props.getRef(this);
  }

  @observable category_edit_visible = false;

  editCategory = () => {
    this.setValue('category_edit_visible', !this.category_edit_visible);
  };

  setCategory = (e) => {
    this.editCategory();
    this.setModalData('categories_path', e);
  };

  @action setModalAdditional = (name, value) => {
    this.modal_data = {
      ...this.modal_data,
      additionals: {
        ...this.modal_data.additionals,
        [name]: value,
      },
    };
    Object.keys(this.modal_data.additionals).map(v => {
      if (this.modal_data.additionals[v] === null || this.modal_data.additionals[v] === '') {
        delete this.modal_data.additionals[v];
      }
    });
  };

  render() {
    const m_data = this.modal_item;

    return (
      <>
        <MDBModal isOpen={this.modal_visible && this.modal_item !== null} toggle={() => this.modalToggle()} size="lg"
                  inline={false} overflowScroll noClickableBodyWithoutBackdrop>
          {m_data && Object.keys(this.modal_data).length > 0 && this.modal_data.constructor === Object && (
            <>
              <MDBModalHeader toggle={() => this.modalToggle()}>{m_data.title_ru}</MDBModalHeader>
              <MDBModalBody>
                <div className={'d-flex justify-content-between align-items-center'}>
                  <div>
                    Категория: {this.modal_data.categories_path.slice().reverse().map(c => c.title_ru).join(' > ')}
                  </div>
                  <MDBBtn onClick={() => this.editCategory()} size={'sm'}
                          color="danger" style={{margin: 0}}>
                    <MDBIcon icon="edit"/>
                  </MDBBtn>
                </div>
                {this.category_edit_visible && <CategorySelectorComponent onChange={e => this.setCategory(e)}/>}
                <MDBInput onChange={(e) => this.setModalData('title_ru', e.target.value)}
                          value={this.modal_data.title_ru} label="Название (RU)"/>
                <MDBInput onChange={(e) => this.setModalData('title_ky', e.target.value)}
                          value={this.modal_data.title_ky} label="Название (KY)"/>
                <MDBInput onChange={(e) => this.setModalData('description_ru', e.target.value)}
                          value={this.modal_data.description_ru} rows={5} type="textarea" label="Описание (RU)"
                          background/>
                <MDBInput onChange={(e) => this.setModalData('description_ky', e.target.value)}
                          value={this.modal_data.description_ky} rows={5} type="textarea" label="Описание (KY)"
                          background/>
                <MDBInput onChange={(e) => this.setModalData('keywords', e.target.value)}
                          value={this.modal_data.keywords} label="Ключевые слова"/>
                <hr/>
                <AdQuestions data={this.modal_data} questions={this.modal_data.categories_path.length > 0 ? this.modal_data.categories_path[0].forms : []}
                             onChange={(name, value) => this.setModalAdditional(name, value)}/>
                {this.modal_data.categories_path.length > 0 && <p>{`{${this.modal_data.categories_path[0].forms.length - 1}}`} Вопросы: {this.modal_data.categories_path[0].forms.join(', ')}</p>}
                {'images' in this.modal_data && this.modal_data.images.length > 0 && (
                  <>
                    <hr />
                    <div>
                      {this.modal_data.images.filter(v => v.split('/')[8].startsWith('thumbnail_')).map(v => (
                        <div onClick={() => this.deleteImage(this.modal_data.id, v)} style={{
                          width: 150,
                          cursor: 'pointer',
                          height: 150,
                          display: 'inline-block',
                          border: '1px solid #808080',
                          margin: 3,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          backgroundImage: `url(${v})`
                        }}/>
                      ))}
                    </div>
                    <div>Нажмите на фото чтобы удалить</div>
                  </>
                )}
              </MDBModalBody>
              <MDBModalFooter>
                {(m_data._busy || m_data._fetching) && (
                  <Loader/>
                )}
                <MDBBtn color="secondary" size={'sm'} onClick={() => this.modalToggle()}>Закрыть</MDBBtn>
                {m_data.status !== 'rejected' && (
                  <MDBBtn color="danger" onClick={() => this.setStatus('reject')} disabled={m_data._busy}
                          size={'sm'}>Отклонить</MDBBtn>
                )}
                {m_data.status !== 'published' && (
                  <MDBBtn color="primary" onClick={() => this.setStatus('publish')} disabled={m_data._busy || this.modal_data.categories_path.length === 0}
                          size={'sm'}>Опубликовать</MDBBtn>
                )}
              </MDBModalFooter>
            </>
          )}
        </MDBModal>
      </>
    );
  }
}

export default AdModerateComponent;
